<template>
    <div>
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-outline-light" data-bs-toggle="modal" data-bs-target="#registerModal" style="border: none">Inscription</button>

        <!-- Modal -->
        <div class="modal fade" id="registerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">Inscription</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form class="row g-3 needs-validation" novalidate>
                <div class="col-md-6">
                  <label for="validationCustomUsername" class="form-label">Nom d'utilisateur</label>
                  <input type="text" class="form-control" id="validationCustomUsername" placeholder="Nom d'utilisateur" v-model="user.name" required>
                  <div class="valid-feedback">
                  C'est bien
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="validationCustomEmail" class="form-label">Adresse e-mail</label>
                  <input type="email" class="form-control" id="validationCustomEmail" placeholder="Adresse e-mail" v-model="user.email" required>
                  <div class="valid-feedback">
                  C'est bien
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="validationCustomPassword01" class="form-label">Mot de passe</label>
                  <input type="password" class="form-control" id="validationCustomPassword01" v-model="user.password" required>
                  <div class="invalid-feedback">
                    Veuillez saisir votre mot de passe.
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="validationCustomPassword02" class="form-label">Confirmation du mot de passe</label>
                  <input type="password" class="form-control" id="validationCustomPassword02" v-model="user.password2" required>
                  <div class="invalid-feedback">
                    Veuillez ressaisir votre mot de passe.
                  </div>
                </div>
                <div class="col-12">
                  <button class="w-100 btn btn-outline-success" type="button" @click="register" data-bs-dismiss="modal" data-bs-toggle='collapse' data-bs-target=".navbar-collapse.show">S'inscrire</button>
                </div>
              </form>
            </div>
            <div class="modal-footer" style="text-align: center">
              <button class="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Déjà inscrit</button>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'RegisterComponent',

  data () {
    return {
      verif: false,
      user: {
        name: '',
        email: '',
        roles: ['ROLE_CLIENT_SHOP'],
        password: '',
        is_verified: false
      },
      registeredUser: false
    }
  },

  methods: {
    async register () {
      console.log('Go')
      if (this.user.password === this.user.password2) {
        console.log('hello')
        console.log(JSON.stringify(this.user))
        await fetch(process.env.VUE_APP_BASE_URL + '/api/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.user)
        })
          .then(response => response.json())
          .then(result => console.log(result))
          .catch(error => error)
        this.registeredUser = true
      } else {
        alert(this.user.password + this.user.password2)
        this.verif = !this.verif
      }
    }
  }
}
</script>
