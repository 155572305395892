export default {

  namespaced: true,

  state: {
    product: null,
    notice: null,
    products: []
  },

  getters: {
    getProducts (state) {
      return state.products
    }
  },

  mutations: {
    RESET_PRODUCT (state) {
      state.product = null
    },

    RESET_NOTICE (state) {
      state.notice = null
    },

    SET_PRODUCT (state, payload) {
      state.product = payload
      console.log(state.product)
    },

    SET_NOTICE (state, payload) {
      state.notice = payload
      console.log(state.notice)
    },

    SET_PRODUCTS (state, payload) {
      state.products = payload
      console.log(state.products)
    }
  },

  actions: {
    resetProduct (context) {
      context.commit('RESET_PRODUCT')
    },

    resetNotice (context) {
      context.commit('RESET_NOTICE')
    },

    async product_show (context, id) {
      const response = await fetch(process.env.VUE_APP_BASE_URL + '/api/products/' + id)
      const data = await response.json()

      console.log(data)

      const product = {
        id: data.id,
        name: data.name,
        price: data.price,
        description: data.description,
        new: data.new,
        image_file: data.image_file,
        id_notice: data.notice_id
      }
      console.log(product)
      context.commit('SET_PRODUCT', product)
    },

    async productNotice (context, idNotice) {
      const response = await fetch(process.env.VUE_APP_BASE_URL + idNotice)
      const data = await response.json()
      console.log(data)

      const notice = {
        Indications: data.indications,
        EffetsSecondaires: data.sideEffects,
        ContreIndications: data.contraIndications,
        InteractionsAlimentaires: data.interactionsFood
      }
      console.log(notice)
      context.commit('SET_NOTICE', notice)
    },

    async productsCategory (context, id) {
      const prods = []
      const response = await fetch(process.env.VUE_APP_BASE_URL + '/api/categories/' + id)
      const data = await response.json()
      const dataResponse = data.products
      console.log(dataResponse)

      for (const key in dataResponse) {
        const responsePC = await fetch(process.env.VUE_APP_BASE_URL + dataResponse[key])
        const dataPC = await responsePC.json()
        console.log(dataPC.status)
        if (dataPC.status === true) {
          const productCategory = {
            id: dataPC.id,
            name: dataPC.name,
            price: dataPC.price,
            description: dataPC.description,
            new: dataPC.new,
            image_file: dataPC.image_file,
            id_notice: dataPC.notice_id
          }
          prods.push(productCategory)
        }
      }
      console.log(prods)
      context.commit('SET_PRODUCTS', prods)
    },

    async productsBrand (context, id) {
      const brandsTab = []
      const response = await fetch(process.env.VUE_APP_BASE_URL + '/api/brands/' + id)
      const data = await response.json()
      const dataResponse = data.idProduct

      for (const key in dataResponse) {
        const responsePB = await fetch(process.env.VUE_APP_BASE_URL + dataResponse[key])
        const dataPB = await responsePB.json()
        console.log(dataPB.status)
        if (dataPB.status === true) {
          const productBrand = {
            id: dataPB.id,
            name: dataPB.name,
            price: dataPB.price,
            description: dataPB.description,
            new: dataPB.new,
            image_file: dataPB.image_file,
            id_notice: dataPB.notice_id
          }
          brandsTab.push(productBrand)
        }
      }
      console.log(brandsTab)
      context.commit('SET_PRODUCTS', brandsTab)
    },

    async productsList (context) {
      const prods = []
      const response = await fetch(process.env.VUE_APP_BASE_URL + '/api/active_prods')
      const data = await response.json()
      console.log(data)
      for (const key in data) {
        // verification du statut activé du produit
        if (data[key].status === true) {
          const product = {
            id: data[key].id,
            name: data[key].name,
            price: data[key].price,
            new: data[key].new,
            description: data[key].description,
            image_file: data[key].image_file,
            id_notice: data[key].notice_id
          }
          prods.push(product)
        }
      }
      console.log(prods)
      context.commit('SET_PRODUCTS', prods)
    }
  }
}
