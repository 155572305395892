<template>
    <div>
       <h2>Paiement réussi!</h2>
    </div>
</template>
<script>
export default {
  name: 'ResultCB'
}
</script>
