<template>
    <div class="transport">
        <div class="container" v-show="!adressDelivery">
            <div class="surPlace">
                <h3>Retrait sur place (Click & Collect) Gratuit</h3>
                <button class="btn btn-outline-success btn-sm" @click="transport(clickCollectPrice)">Valider</button>
            </div>
            <div class="parTransporteur">
                <h3>Livraison par transporteur:</h3>
                <h3>
                    {{ transportPrice }} €
                </h3>
                <p>Sous 48 à 72h en fonction des stocks disponibles</p>
                <button class="btn btn-outline-success btn-sm" @click="transport(transportPrice)">Valider</button>
            </div>
        </div>
        <div class="adress" v-show="adressDelivery">
           <DeliveryAdress @adressValid="validPath($event)"/>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import DeliveryAdress from './ordersElements/DeliveryAdress.vue'
export default {
  name: 'TransportsComponent',

  data () {
    return {
      transportPrice: 11.90,
      clickCollectPrice: 0,
      adressDelivery: false
    }
  },

  components: {
    DeliveryAdress
  },

  computed: {
    ...mapState({
      amountCart: state => state.cart.amountCart,
      amountTransport: state => state.cart.amountTransport
    }),
    ...mapActions(['cart/newTransport'])
  },

  methods: {
    transport (value) {
      console.log('insertion du montant du transport dans le store')
      this.$store.dispatch('cart/newTransport', value)
      if (value !== 0) {
        this.adressDelivery = true
      } else {
        console.log(this.$store.state.amountTransport)
        this.adressDelivery = true
        // this.$emit('deliveryOk', true);
      }
    },

    validPath (value) {
      console.log('valide')
      this.adressDelivery = value
      this.$emit('deliveryOk', true)
    }
  }
}
</script>
<style lang="css" scoped>
    .container{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .surPlace, .parTransporteur{
        border-color: rgb(101, 175, 101);
        border-radius: 10px;
        border: solid green;
        margin: 20px;
        padding: 10px;
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

</style>
