<template>
    <div>
        <a class="cart_button position-relative" data-bs-toggle="modal" data-bs-target="#cart">
          <i class="bi bi-cart4" style="font-size: 25px; color: white"></i>
          <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success" style="font-size: 10px">{{ cart.length }} <span class="visually-hidden">unread messages</span></span>
        </a>

        <!-- Modal -->
        <div class="modal fade" id="cart" tabindex="-1" aria-labelledby="cart" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Mon panier d'achat</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                 <table class="table" style="background-color: light">
                    <thead>
                        <tr>
                            <th>image</th>

                            <th>Quantité</th>
                            <th>Nom</th>
                            <th>Prix</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody
                            class="content"
                            v-for="prod in cart"
                            :key="prod.id"
                            >
                        <tr>
                            <td><img :src= "image_base_url + prod.imageFile" alt="prod.name" width="50" ></td>
                            <td>{{ prod.quantity }}</td>
                            <td>{{ prod.name }}</td>
                            <td>{{ prod.price }} €</td>
                            <td><i class="bi bi-trash" @click="removeProductFromCart(prod.id, prod.name, prod.price, 0, prod.imageFile)"></i></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td></td>
                            <td scope="row"><strong>Total Panier:</strong></td>
                            <td><strong>{{ amountCart.toFixed(2) }} €</strong></td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-success" data-bs-dismiss="modal" data-bs-toggle='collapse' data-bs-target=".navbar-collapse.show">Poursuivre mes achats</button>
                <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal" data-bs-toggle='collapse' data-bs-target=".navbar-collapse.show" @click="orderCart">Commander</button>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'CartComponent',
  data () {
    return {
      image_base_url: process.env.VUE_APP_BASE_URL + '/images/products/'
    }
  },
  computed: {
    ...mapState({
      cart: state => state.cart.cart,
      amountCart: state => state.cart.amountCart
    })
  },
  methods: {
    orderCart () {
      this.$router.push('/commande')
    },

    removeProductFromCart (id, name, price, quantity, imageFile) {
      const prod =
        {
          id,
          name,
          price,
          quantity,
          imageFile
        }
      console.log('panier' + prod)
      this.$store.dispatch('cart/addInCart', prod)
    }
  }
}
</script>
