const cart = sessionStorage.getItem('cart')
const amountCart = sessionStorage.getItem('amountCart')

export default {

  namespaced: true,

  state: {
    // verification du panier dans le sessionStorage
    cart: cart ? JSON.parse(cart) : [], // contenu du panier
    // totalCart:[],
    amountCart: amountCart ? parseFloat(amountCart) : 0, // cout total du panier
    amountTransport: 0 // coût du transport
  },

  gatters: {
    getInCart (state) {
      return state.cart
    },

    getTransport (state) {
      return state.amountTransport
    }
  },

  mutations: {

    SET_IN_CART (state, prod) {
      // ajout du produit dans le panier
      if (state.cart.length === 0) {
        if (prod.quantity !== 0) {
          state.cart.push(prod)
        }
      } else {
        // controle du doublon dans le panier
        for (let i = 0; i < state.cart.length; i++) {
          if (prod.id === state.cart[i].id) {
            state.cart.splice(i, 1) // suppression du produit
          }
        }
        if (prod.quantity !== 0) {
          state.cart.push(prod) // ajout du produit
        }
      }
      // remise à zero du montant total:
      state.amountCart = 0

      // calcul du montant du panier:
      for (let i = 0; i < state.cart.length; i++) {
        if (state.cart.length !== 0) {
          console.log('price: ' + state.cart[i].price)
          console.log('qté: ' + state.cart[i].quantity)
          console.log('montant: ' + state.amountCart)
          state.amountCart = state.amountCart + (state.cart[i].quantity * state.cart[i].price)
        }
      }

      // sauvegarde en sessionStorage
      sessionStorage.setItem('cart', JSON.stringify(state.cart))
      sessionStorage.setItem('amountCart', JSON.stringify(state.amountCart))
    },

    CLEAN_CART (state) {
      // vidage du panier
      state.cart = []
      sessionStorage.removeItem('cart')
      // remise à zero du montant du panier
      state.amountCart = 0
      sessionStorage.removeItem('amountCart')
    },

    NEW_TRANSPORT (state, typeTransport) {
      state.amountTransport = typeTransport
    }
  },

  actions: {
    addInCart (context, productToBuy) {
      context.commit('SET_IN_CART', productToBuy)
    },

    cleanCart (context) {
      context.commit('CLEAN_CART')
    },

    newTransport (context, typeTransport) {
      context.commit('NEW_TRANSPORT', typeTransport)
    }
  }
}
