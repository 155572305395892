export default {
  namespaced: true,

  state: {
    brands: []
  },

  getters: {
    getBrands (state) {
      return state.brands
    }
  },

  mutations: {
    SET_BRANDS (state, payload) {
      state.brands = payload
    }
  },

  actions: {
    async brandsList (context) {
      const brandsTab = []
      const Response = await fetch(process.env.VUE_APP_BASE_URL + '/api/brands')
      const data = await Response.json()
      const dataResponse = data['hydra:member']
      console.log(dataResponse)
      for (const key in dataResponse) {
        const brand = {
          id: dataResponse[key].id,
          name: dataResponse[key].name,
          imageFile: dataResponse[key].imageFile,
          imagePath: dataResponse[key].imagePath
        }
        brandsTab.push(brand)
      }
      context.commit('SET_BRANDS', brandsTab)
    }
  }
}
