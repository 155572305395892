export default {
  namespaced: true,
  state: {
    categories: []
  },
  getters: {
    getCategories (state) {
      return state.categories
    }
  },
  mutations: {
    SET_CATEGORIES (state, payload) {
      state.categories = payload
    }
  },
  actions: {
    async categoriesList (context) {
      const categs = []
      const Response = await fetch(process.env.VUE_APP_BASE_URL + '/api/categories')
      const data = await Response.json()
      const dataResponse = data['hydra:member']
      console.log(dataResponse)
      for (const key in dataResponse) {
        const category = {
          id: dataResponse[key].id,
          name: dataResponse[key].name
        }
        categs.push(category)
      }
      context.commit('SET_CATEGORIES', categs)
    }
  }
}
