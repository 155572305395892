<template>
    <div class="col-md-6 mt-5 mx-auto">
        <h1>Formulaire CB</h1>
      <h3> Montant à régler: {{ CBtotalAmount }} Euros</h3>
        <Message :alert="alert" v-if="alert" />
            <form>
                <div class="form-group">
                    <label for="cardType">Type de carte: </label>
                        <select
                            id="inputState"
                            v-model="CB.cardType"
                            class="form-control"
                            name="cardType"
                            >
                            <option selected>Quel type de carte? </option>
                            <option value="138">Carte Bleue</option>
                            <option value="1">Visa</option>
                            <option value="3">Master Card</option>
                        </select>
                </div>
                <div class="form-group">
                    <label for="cardHolderName">Titulaire de la carte: </label>
                    <input
                        id="cardHolderName"
                        v-model="CB.cardHolderName"
                        class="form-control"
                        type="text"
                        name="cardHolderName"
                        >
                </div>
                <div class="form-group">
                    <label for="cardNumber">Numero de Carte: 16 chiffres</label>
                    <input
                        id="cardNumber"
                        v-model="CB.cardNumber"
                        class="form-control"
                        type="text"
                        name="cardNumber"
                        >
                </div>
                <div class="form-group">
                    <label for="expiryDate">Date d'expiration: </label>
                    <input
                        id="expiryDate"
                        v-model="CB.expiryDate"
                        class="form-control"
                        type="text"
                        name="expiryDate"
                        placeholder="MMyy"
                        >
                </div>
                <div class="form-group">
                    <label for="cvv">cvv: 3 chiffres</label>
                    <input
                        id="cvv"
                        v-model="CB.cvv"
                        class="form-control"
                        type="text"
                        name="cvv"
                        >
                </div>
                <input
                class="btn btn-sm btn-primary btn-block"
                @click="payment"
                type="button"
                value="Payer">
            </form>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import axios from 'axios'
import Message from '@/components/orders/Message.vue'
export default {
  name: 'CbComponent',

  components: {
    Message
  },

  data () {
    return {
      alert: null,
      CB: {
        amount: 0,
        cardType: null,
        cardHolderName: null,
        cardNumber: null,
        expiryDate: null,
        cvv: null
      },
      invoiceTab: {
        idUser: '',
        billingNumber: '',
        userInvoice: '',
        productCart: '',
        amountCart: '',
        transportCost: '',
        urlInvoice: ''
      }
    }
  },
  computed: {
    ...mapState({
      amountCart: state => state.cart.amountCart,
      user: state => state.user.user,
      cart: state => state.cart.cart,
      amountTransport: state => state.cart.amountTransport
    }),
    ...mapActions['cart/cleanCart'],
    CBtotalAmount: {
      get () {
        console.log(this.$store.state.cart.amountCart)
        console.log(this.$store.state.cart.amountTransport)
        const total = parseFloat(this.$store.state.cart.amountCart)
        const transport = parseFloat(this.$store.state.cart.amountTransport)
        return (total + transport).toFixed(2)
      }
    }
  },

  created () {
    // calcul du montant total de la commande (transport compris)
    console.log(this.$store.state.cart.amountCart)
    console.log(this.$store.state.cart.amountTransport)
    const total = parseFloat(this.$store.state.cart.amountCart)
    const transport = parseFloat(this.$store.state.cart.amountTransport)
    this.CB.amount = total + transport
    console.log('Total CB: ' + this.CB.amount)
  },

  methods: {

    async payment (e) {
      e.preventDefault()

      // Montant global:
      this.CB.amount = this.CBtotalAmount

      // Paiement CB
      console.log(JSON.stringify(this.CB))
      axios.post(process.env.VUE_APP_BASE_URL + '/payment_cb_api', JSON.stringify(this.CB))
        .then(response => {
          console.log('CB: ' + response.statusText)
          // faire la facture
          this.makeInvoice()
          // vider le panier
          this.$store.dispatch('cart/cleanCart')
          // afficher la validation du paiement
          this.$router.push('ResultCB')
        })
        .catch(error => {
          console.log(error.message)
          this.alert = error.message
        })
    },

    async makeInvoice () {
    // transmettre les éléments pour faire la facture (adresse, produits, montants au serveur
      const date = new Date()
      const number = date.getTime()
      const tab = [
        this.invoiceTab.idUser = sessionStorage.getItem('id'),
        this.invoiceTab.billingNumber = number,
        this.invoiceTab.userInvoice = this.$store.state.user.user,
        this.invoiceTab.productCart = this.$store.state.cart.cart,
        this.invoiceTab.amountCart = this.$store.state.cart.amountCart,
        this.invoiceTab.transportCost = this.$store.state.cart.amountTransport,
        this.invoiceTab.urlInvoice = 'invoice' + number + '.pdf'
      ]
      console.log(tab)
      console.log(JSON.stringify(tab))
      console.log(JSON.stringify(this.invoiceTab))
      fetch(process.env.VUE_APP_BASE_URL + '/api/post/invoices', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(tab)
      })
        .then(response => {
          console.log('Invoice: ' + response.statusText)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
<style>

</style>
