<template>
    <div class="breadcrumbs_group">
        <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li
                class="breadcrumb-item"
                v-for="(breadcrumb, id) in breadcrumbs"
                :key="id"
                >
                <a @click="goToLink(breadcrumb)">{{ breadcrumb }}</a>
                </li>
            </ol>
        </nav>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'BreadCrumbs',
  computed: {
    ...mapState({
      breadcrumbs: state => state.breadcrumbs.breadcrumbs,
      categories: state => state.categories.categories,
      brands: state => state.brands.brands
    })
  },
  methods: {
    // permet de remettre product = null dans le state
    reset () {
      this.$store.dispatch('breadcrumbs/cleanBreadCrumbs') // remet le breadcrumbs à vide
      this.$store.dispatch('products/resetProduct') // remet product = null dans le state
    },

    goToLink (link) {
      if (link === 'Accueil') {
        this.$router.push('/')
      } else {
        for (const key in this.categories) {
          console.log(key)
          if (link === this.categories[key].name) {
            this.goToCateg(this.categories[key].id, link)
          }
        }
        for (const key in this.brands) {
          console.log(key)
          if (link === this.brands[key].name) {
            this.goToBrand(this.brands[key].id, link)
          }
        }
      }
    },

    goToCateg (id, categName) {
      this.reset()
      this.$store.dispatch('breadcrumbs/addInBreadCrumbs', categName)
      this.$store.dispatch('products/productsCategory', id)
      this.$router.push('/produits')
    },

    goToBrand (id, brandName) {
      this.reset()
      this.$store.dispatch('breadcrumbs/addInBreadCrumbs', brandName)
      this.$store.dispatch('products/productsBrand', id)
      this.$router.push('/produits')
    }
  }
}
</script>
<style lang="css">
    .breadcrumb-item{
        cursor: pointer;
    }
</style>
