<template>
<div>
  <!-- Button trigger modal -->
  <button type="button" class="btn btn-outline-light" data-bs-toggle="modal" data-bs-target="#loginModal" style="border: none">Connexion</button>

  <!-- Modal -->
  <div class="modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
    <div class="modal-content rounded-4 shadow">
      <div class="modal-header p-5 pb-4 border-bottom-0">
        <!-- <h5 class="modal-title">Modal title</h5> -->
        <h2 class="fw-bold mb-0">Connexion</h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="pwdForgotten"></button>
      </div>

      <div
      v-if="mdp"
      class="modal-body p-5 pt-0"
      >
        <form class="login-form">
          <div class="form-floating mb-3">
            <input type="email" class="form-control rounded-3" id="floatingInput" placeholder="nom@exemple.com" v-model="user.email" required>
            <label for="floatingInput">Adresse e-mail</label>
          </div>
          <div class="form-floating mb-3">
            <input type="password" class="form-control rounded-3" id="floatingPassword" placeholder="Mot de passe" v-model="user.password" required>
            <label for="floatingPassword">Mot de passe</label>
            <a @click="pwdForgotten" style="cursor: pointer; text-decoration: underline">Mot de passe oublié</a>
          </div>
          <button class="w-100 mb-2 btn btn-lg rounded-3 btn-outline-success" type="button" @click="handleSubmit" data-bs-dismiss="modal" data-bs-toggle='collapse' data-bs-target=".navbar-collapse.show">Se connecter</button>
          <p><small class="text-muted">Vos données sont protégées</small></p>
          <hr class="my-4">
          <button class="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">Pas encore inscrit</button>
        </form>
      </div>
      <div
         v-else
         class="modal-body p-5 pt-0"
        >
          <form>
            <div class="form-floating mb-3">
              <input type="email" class="form-control rounded-3" id="floatingEmail" placeholder="Email" v-model="user_forgot_pwd.email" required>
              <label for="floatingPassword">Email de réinitialisation du mot de passe</label>
              <button class="w-100 mb-2 btn btn-lg rounded-3 btn-outline-success" type="button" @click="reinitMdp" data-bs-dismiss="modal" data-bs-toggle='collapse' data-bs-target=".navbar-collapse.show">Réinitialiser</button><br>
              <a @click="pwdForgotten" style="cursor: pointer; text-decoration: underline">Retour</a>
            </div>
          </form>
      </div>
    </div>
  </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'LoginComponent',

  data () {
    return {
      mdp: true,
      user: {
        email: '',
        password: ''
      },
      user_forgot_pwd: {
        email: ''
      }
    }
  },

  methods: {
    pwdForgotten () {
      this.mdp = !this.mdp
    },
    async handleSubmit () {
      await fetch(process.env.VUE_APP_BASE_URL + '/api/login_check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.user)
      })
        .then(response => {
          return response.json()
        })
        .then(result => {
          console.log(result)
          console.log(result.data)
          sessionStorage.setItem('tok', result.token)
          sessionStorage.setItem('id', result.data.id)
          sessionStorage.setItem('name', result.data.name)
          sessionStorage.setItem('email', result.data.email)
          this.$store.dispatch('user/newUser', result.data.name)
          this.$router.push('/produits')
        })
        .catch(error => console.log(error))
    },

    async reinitMdp () {
      const response = await fetch(process.env.VUE_APP_BASE_URL + '/api/forgot_pwd', {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(this.user_forgot_pwd)
      })
      const result = await response.json()
      console.log(result)
      this.mdp = !this.mdp
    }
  }

}
</script>
<style lang="css" scoped>
    .modal-sheet .modal-dialog {
  width: 380px;
  transition: bottom .75s ease-in-out;
}
.modal-sheet .modal-footer {
  padding-bottom: 2rem;
}

.modal-alert .modal-dialog {
  width: 380px;
}

.modal-tour .modal-dialog {
  width: 380px;
}
</style>
