<template>
    <div class="message">
        {{ alert }}
    </div>
</template>
<script>
export default {
  name: 'MessageComponent',
  props: [
    'alert'
  ]
}
</script>
<style scoped>
.message{
    color: red
}
</style>
