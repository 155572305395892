export default {
  namespaced: true,

  state: {
    user: null
  },

  getters: {
    getUser (state) {
      return state.user
    }
  },

  mutations: {
    NEW_USER (state, profile) {
      state.user = profile
    }
  },

  actions: {
    newUser (context, profile) {
      context.commit('NEW_USER', profile)
    }
  }
}
