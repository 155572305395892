<template>
    <div>
        <h3>Adresse de livraison</h3>
        <form class="form-address">
            <div class="form-row">
                <div class="form-group">
                    <label for="inputEmail4">Email: </label>
                    {{ addressUser.email }}
                </div>
                <div class="form-group">
                    <label for="inoutPhone">Téléphone</label>
                    <input type="text" class="form-control" id="inoutPhone" placeholder="Télephone" v-model="addressUser.phone">
                </div>
                <div class="form-group">
                    <label for="Nom">Nom</label>
                    {{ addressUser.lastName }}
                </div>
                <div class="form-group">
                    <label for="prenom">Prénom</label>
                    <input type="text" class="form-control" id="prenom" placeholder="Prénom" v-model="addressUser.firstName">
                </div>
            </div>
            <div class="form-group">
                <label for="inputAddress">Adresse</label>
                <input type="text" class="form-control" id="inputAddress" placeholder="Votre adresse de livraison" v-model="addressUser.address">
            </div>

            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="inputCity">Ville</label>
                    <input type="text" class="form-control" id="inputCity" v-model="addressUser.city">
                </div>
                    <div class="form-group col-md-4">
                    <label for="inputState">Pays</label>
                    <select id="inputState" class="form-control" v-model="addressUser.country">
                        <option selected>France</option>
                        <option>Belgique</option>
                        <option>Suisse</option>
                        <option>Luxembourg</option>
                    </select>
                </div>
                <div class="form-group col-md-2">
                    <label for="inputZip">Code Postal</label>
                    <input type="text" class="form-control" id="inputZip" v-model="addressUser.zipCode">
                </div>
            </div>
            <div>
                <button type="button" class="btn btn-outline-success" @click="handleSubmit('livraison')">Valider</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
  name: 'FormAdress',

  data () {
    return {
      addressUser: {
        id: sessionStorage.getItem('id'),
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        zipCode: '',
        country: ''
      }
    }
  },

  created () {
    fetch(process.env.VUE_APP_BASE_URL + '/api/users/' + sessionStorage.getItem('id'), {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('tok')
      }
    })
      .then(response => {
        return response.json()
      })
      .then(json => {
        console.log(json)
        console.log(json.name)
        this.addressUser.email = json.email
        this.addressUser.lastName = json.name
      })
  },

  methods: {

    handleSubmit (formType) {
      console.log(formType)
      fetch(process.env.VUE_APP_BASE_URL + '/api/registerAddressUser/' + sessionStorage.getItem('id'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.addressUser)
      })
      this.$router.push('/commande')
    }

  }

}
</script>
<style lang="css" scoped>
  .form-address{
    margin-left: 200px;
    margin-right: 200px;
  }
</style>
