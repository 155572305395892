<template>
    <div>
        <h3>Récapitulatif de ma commande:</h3>
        <table class="table" style="background-color: light">
            <thead>
                <tr>
                    <th>Quantité</th>
                    <th>Nom</th>
                    <th>Prix</th>
                </tr>
            </thead>
            <tbody
                    class="content"
                    v-for="prod in cart"
                    :key="prod.id"
                    >
                <tr>
                    <td scope="row">{{ prod.quantity }}</td>
                    <td>{{ prod.name }}</td>
                    <td>{{ prod.price }} €</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td scope="row"><strong>Total Panier:</strong></td>
                    <td></td>
                    <td><strong>{{amountCart.toFixed(2)}} €</strong></td>
                </tr>
            </tfoot>
        </table>

        <button type="submit" class="btn btn-outline-success" @click="orderValid">Valider ma commande</button>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {

  name: 'RecapCommandes',

  data () {
    return {
      orderOk: true
    }
  },

  computed: {
    ...mapState({
      cart: state => state.cart.cart,
      amountCart: state => state.cart.amountCart
    })
  },

  methods: {
    orderValid () {
      this.$emit('orderValidEvent', this.orderOk)
    }
  }
}
</script>
