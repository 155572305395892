import { createStore } from 'vuex'
import loadingModule from './loading/index.js'
import productsModule from './products/index.js'
import categoriesModule from './categories/index.js'
import brandsModule from './brands/index.js'
import breadcrumbs from './breadcrumbs/index.js'
import cartModule from './cart/index.js'
import userModule from './user/index.js'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    loading: loadingModule,
    products: productsModule,
    categories: categoriesModule,
    brands: brandsModule,
    breadcrumbs: breadcrumbs,
    cart: cartModule,
    user: userModule
  }
})
