<template>
    <div class="enseigne">
        <div id="pharmacie">PHARMACIE</div>   <div id="parapharmacie">PARAPHARMACIE</div>   <div id="orthopedie">ORTHOPEDIE</div>   <div id="materiel">MATERIEL MEDICAL</div>
    </div>
</template>
<script>
export default {
  name: 'EnseigneTop'
}
</script>
<style lang="css" scoped>
    .enseigne{
        background-color: #233E8B;
        color: white;
        font-size: 50px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        height: 60px;
    }

    @media screen and (max-width: 1700px) {
        .enseigne{
        font-size: 20px;
        }
    }

    @media screen and (max-width: 670px) {
        .enseigne{
            font-size: 20px;
        }
      #materiel, #orthopedie {
            display: none;
        }
    }
</style>
