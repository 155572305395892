<template>
    <div>
        <RecapCommandes v-show="!orderOk" @orderValidEvent="nextPath($event)" />
        <Transports v-show="transportOk" @deliveryOk="validPath($event)"/>
        <CbComponent v-show="userOk" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import RecapCommandes from '@/components/orders/RecapCommandes.vue'
import Transports from '@/components/orders/Transports.vue'
import CbComponent from '@/components/orders/Cb.vue'
export default {
  name: 'OrderView',

  data () {
    return {
      orderOk: false,
      transportOk: false,
      adressOk: false,
      userOk: false
    }
  },

  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },

  components: {
    RecapCommandes,
    Transports,
    CbComponent
  },

  methods: {
    nextPath (validation) {
      console.log('parent: ' + validation)
      this.transportOk = validation
      this.orderOk = true
    },

    validPath (validation) {
      console.log('parent: ' + validation)
      this.adressOk = validation
      this.transportOk = false
      this.userOk = true
    }
  }
}
</script>
