<template>
    <div>
        <h2>Page en cours, merci de votre compréhension</h2>
    </div>
</template>

<script>
export default {
  name: 'InfoPage'
}
</script>
