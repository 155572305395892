<template>
    <div class="loading">
        <img src="../../assets/images/loading.gif" alt="loading">
    </div>
</template>

<script>
export default {
  name: 'LoadingComponent'
}
</script>

<style lang="css" scoped>
    .loading{
        margin: 20px;
    }
    img{
        width: 20%;
    }
</style>
