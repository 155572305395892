<template>
  <enseigne />
  <nav-bar />
  <div
  v-if="loading === true"
  >
    <loading-components />
  </div>
  <div
  v-else
  >
    <router-view style="margin: 10px"/>
  </div>
  <footer-bar />
</template>

<script>
import NavBar from '@/components/_parts/nav.vue'
import FooterBar from '@/components/_parts/footer.vue'
import Enseigne from './components/_parts/enseigne.vue'
import { mapState } from 'vuex'
import LoadingComponents from './components/_utils/Loading.vue'

export default {
  name: 'App',

  components: {
    NavBar,
    FooterBar,
    Enseigne,
    LoadingComponents
  },

  created () {
    // this.$store.dispatch('insertInCart', sessionStorage.getItem('cart'));
    if (sessionStorage.getItem('tok')) {
      this.$store.dispatch('user/newUser', sessionStorage.getItem('name'))
    }
  },

  computed: {
    ...mapState({
      loading: state => state.loading.loading
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
