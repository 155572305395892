<template>
    <div>
        <div class="card">
                  <div class="image-container">
                    <img :src= "image_base_url + imageFile" class="card-img-top" @click="showProduct(id, name, id_notice)" alt="name">
                    <div class="new_prod" v-if= "new_prod" >
                      NOUVEAU
                    </div>
                  </div>
                    <div class="card-body">
                        <h5 class="card-title-name" @click="showProduct(id, name, id_notice)">{{ name }}</h5>
                        <h5 class="card-title-price">{{ price }}€</h5>
                        <p class="card-text">{{ description }}</p>
                        <div class="input-group" style="justify-content: center">
                          <input type="number" min="1" style="width:20%" v-model="quantity" placeholder="0">
                          <button class="btn btn-sm btn-outline-success" @click="fillCart(id, name, price, quantity, imageFile)">Ajouter <i class="bi bi-cart-plus"></i></button>
                        </div>
                    </div>
                </div>
    </div>
</template>
<script>
export default {
  name: 'card_products',
  props: {
    id: Number,
    id_notice: Number,
    new: Boolean,
    name: String,
    price: String,
    description: String,
    imageFile: String
  },
  data () {
    return {
      image_base_url: process.env.VUE_APP_BASE_URL + '/images/products/',
      quantity: 0,
      new_prod: this.new // recupération de la prop booleenne: new
    }
  },
  methods: {
    showProduct (id, productName, idNotice) {
      this.$store.dispatch('breadcrumbs/addInBreadCrumbs', productName)
      this.$store.dispatch('products/product_show', id)
      if (idNotice !== undefined) {
        this.$store.dispatch('products/productNotice', idNotice)
      } else {
        this.$store.dispatch('products/resetNotice')
      }
      this.$router.push('/produits')
    },

    fillCart (id, name, price, quantity, imageFile) {
      const prod =
        {
          id,
          name,
          price,
          quantity,
          imageFile
        }
      console.log('panier' + prod)
      if (prod.quantity) {
        this.$store.dispatch('cart/addInCart', prod)
      }
    }
  }
}
</script>
<style lang="css" scoped>

    .card{
      margin: 20px;
      height: 480px;
      width: 18rem
    }
    .image-container{
      height: 288px;
      width:  288px;
      display: flex;
      justify-content: center;
      text-align: center;
    }
    .card-img-top{
      position: absolute;
      justify-content: center;
      height: 50%;
      width: auto;
      display: block;
      cursor: pointer;
      transition: transform .2s; /* Animation */
    }
    .new_prod{
      position: relative;
      top: 10px;
      left: 90px;
      font-weight: bold;
      color: white;
      background-color: #A9F1DF;
      padding: 5px;
      height: 30px;
    }
    .card-title-name{
      height: 50px;
      overflow: hidden;
      font-weight: bold;
      cursor: pointer
    }
    .card-img-top:hover{
      transform: scale(1.1);
    }

</style>
