<template>
  <div class="home">
    <div id="myCarousel" class="carousel carousel-dark slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div
        v-for="item in dataCarousel"
        :key="item.id"
        :class="item.id === dataCarousel[0].id ? 'carousel-item active' : 'carousel-item'"
        >
          <img class="bd-placeholder-img-carousel" :src="item.url_image" :alt="item.id">
          <div class="carousel-caption text-start">
            <h1>{{ item.title }}</h1>
            <p>{{ item.description }}</p>
            <p><a class="btn btn-sm btn-success" :href="item.link_products">Je vais voir!</a></p>
          </div>
        </div>
      </div>
        <button class="carousel-control-prev" data-bs-target="#myCarousel" type="button" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Précedent</span>
        </button>
        <a class="carousel-control-next" data-bs-target="#myCarousel" type="button" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Suivant</span>
        </a>
    </div>
    <welcome-home />
  </div>
</template>

<script>

// @ is an alias to /src
import WelcomeHome from '@/components/Welcome.vue'

export default {
  name: 'HomeView',
  components: {
    WelcomeHome
  },
  data () {
    return {
      data_carousel_url: process.env.VUE_APP_BASE_URL + '/api/carousel',
      dataCarousel: []
    }
  },
  async created () {
    this.getCarousel()
  },
  methods: {
    async getCarousel () {
      const response = await fetch(this.data_carousel_url)
      const json = await response.json()
      console.log(json)
      this.dataCarousel = json
      console.log(this.dataCarousel)
    }
  }
}
</script>

<style lang="css" scoped>
  /* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
}

/* taille de l'image du carousel */
.bd-placeholder-img-carousel {

}

@media screen and (max-width: 1500px){
  .bd-placeholder-img-carousel {
    width: 80%;
  }
}

@media screen and (max-width: 1000px){
  .bd-placeholder-img-carousel {
    width: 100%;
  }
    .carousel-caption {
    bottom: 10rem;
    z-index: 10;
  }
}

@media screen and (max-width: 650px){
  .bd-placeholder-img-carousel {
    width: 150%;
  }
  .carousel-caption {
    bottom: 14rem;
    z-index: 10;
  }
  .carousel {
    margin-bottom: 2rem;
  }
}
</style>
