const breadcrumbs = sessionStorage.getItem('breadcrumbs')

export default {
  namespaced: true,
  state: {
    breadcrumbs: breadcrumbs ? JSON.parse(breadcrumbs) : ['Accueil']
  },
  getters: {
    getBreadCrumbs (state) {
      return state.breadcrumbs
    }
  },
  mutations: {
    SET_BREADCRUMBS (state, payload) {
      state.breadcrumbs.push(payload)
      console.log(state.breadcrumbs)
      sessionStorage.setItem('breadcrumbs', JSON.stringify(state.breadcrumbs))
    },

    CLEAN_BREADCRUMBS (state) {
      state.breadcrumbs = ['Accueil']
      sessionStorage.removeItem('breadcrumbs')
    }
  },
  actions: {
    addInBreadCrumbs (context, item) {
      context.commit('SET_BREADCRUMBS', item)
    },

    cleanBreadCrumbs (context) {
      context.commit('CLEAN_BREADCRUMBS')
    }

  }
}
