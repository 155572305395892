<template>
    <div>
        <div v-show="!user" class="profile">
           <h2>Merci de vous connecter ou de vous inscrire</h2>
        </div>
        <div v-show="user">
            <div v-if="addressExist">
                 <p>Adresse de livraison et facturation</p>
                    {{ dataBillingAddress.address }}
                    {{ dataBillingAddress.city }}
                    {{ dataBillingAddress.zipcode }}
                    {{ dataBillingAddress.country }}
                <button type="button" class="btn btn-outline-success" @click="handleSubmit">Valider</button>
                <div>
                    <span>Je souhaite une livraison à une adresse différente: </span>
                    <button type="button" @click="adressInvoice" class="btn btn-outline-success">Adresse de livraison</button>
                </div>
            </div>
            <div v-else>
                Vous n'avez pas indiqué d'adresse dans votre compte:
                <button type="button" @click="createAdress" class="btn btn-outline-success">Mon adresse</button>
            </div>
        </div>
    </div>
</template>
<script>
// import LoginComponent from '@/components/user/LoginComponent.vue'
// import RegisterComponent from '@/components/user/RegisterComponent.vue'
import { mapState } from 'vuex'
// import FormAdress from "../elements/FormAdress"
export default {
  name: 'AdressDelivery',

  components: {
    // FormAdress,
    // LoginComponent,
    // RegisterComponent
  },

  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },

  data () {
    return {
      addressExist: false,
      otherAdress: false,
      transportOk: false,
      dataBillingAddress: {
        address: '',
        zipcode: '',
        city: '',
        country: ''
      }
    }
  },

  async created () {
    await fetch(process.env.VUE_APP_BASE_URL + '/api/user_addresses', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('tok')
      }
    })
      .then(Response => {
        return Response.json()
      })
      .then(json => {
        let i
        console.log(json['hydra:totalItems'])
        const totalItems = json['hydra:totalItems']
        console.log(json['hydra:member'])
        const addresses = json['hydra:member']
        for (i = 0; i < totalItems; i++) {
          if (addresses[i].userId === '/api/users/' + sessionStorage.getItem('id')) {
            this.dataBillingAddress.address = addresses[i].address
            this.dataBillingAddress.zipcode = addresses[i].zipcode
            this.dataBillingAddress.city = addresses[i].city
            this.dataBillingAddress.country = addresses[i].country
          }
          console.log('Code Postale: ' + this.dataBillingAddress.zipcode)
          if (this.dataBillingAddress.zipcode === '') {
            this.addressExist = false
            console.log(this.addressExist)
          } else {
            this.addressExist = true
          }
        }
      })
  },

  methods: {
    handleSubmit () {
      this.$emit('adressValid', this.transportOk)
    },

    adressInvoice () {
      this.otherAdress = !this.otherAdress
    },

    createAdress () {
      this.$router.push('/compte_utilisateur')
    }
  }

}
</script>
