<template>
    <div
    class="brandsList"
    v-for="(brand, item) in brands"
    :key="item"
    >
    <li class="dropdown-item">
       <a class="dropdown-item" @click="BrandLink(brand.id, brand.name)" data-bs-toggle='collapse' data-bs-target=".navbar-collapse.show">{{ brand.name }}</a>
    </li>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'BrandsList',
  computed: {
    ...mapState({
      brands: state => state.brands.brands,
      loading: state => state.loading.loading
    })
  },
  async created () {
    this.$store.dispatch('brands/brandsList')
  },
  methods: {
    // dirige vers les produits de la marque à l'aide de la fonction productsBrand
    BrandLink (id, brandName) {
      this.$store.dispatch('loading/loadingEffect', true)
      console.log(this.loading)
      this.$router.push('/produits')
      this.$store.dispatch('breadcrumbs/cleanBreadCrumbs')
      this.$store.dispatch('breadcrumbs/addInBreadCrumbs', brandName)
      this.$store.dispatch('products/resetProduct')
      this.$store.dispatch('products/productsBrand', id)
      this.$store.dispatch('loading/loadingEffect', false)
    }
  }
}
</script>
<style lang="css" scoped>
    .brandsList{
        cursor: pointer;
    }
</style>
