<template>
    <div
    class="categ"
    v-for="(category, item) in categories"
    :key="item"
    >
    <li class="dropdown-item">
        <a class="dropdown-item" @click="CategLink(category.id, category.name)" data-bs-toggle='collapse' data-bs-target=".navbar-collapse.show">{{ category.name }}</a>
    </li>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'CategoriesList',
  computed: {
    ...mapState({
      categories: state => state.categories.categories
    })
  },
  async created () {
    this.$store.dispatch('categories/categoriesList')
  },
  methods: {
    // dirige vers les produits de la catégorie a l'aide de la fonction productsCategory dans le store
    CategLink (id, categName) {
      this.$router.push('/produits')
      this.$store.dispatch('breadcrumbs/cleanBreadCrumbs')
      this.$store.dispatch('breadcrumbs/addInBreadCrumbs', categName)
      this.$store.dispatch('products/resetProduct')
      this.$store.dispatch('products/productsCategory', id)
    }
  }
}
</script>

<style lang="css" scoped>
    .dropdown-item{
        cursor: pointer;
    }
</style>
