<template>
    <div>
        <div class="container">
        <footer class="py-5 border-top">
                <div class="row">
                <div class="col-6 col-md-2 mb-3">
                    <h5>Shop-Flash</h5>
                    <ul class="nav flex-column">
                    <li class="nav-item mb-2"><router-link to="/en_cours" class="nav-link p-0 text-muted">Equipe</router-link></li>
                    <li class="nav-item mb-2"><router-link to="/en_cours" class="nav-link p-0 text-muted">Pharmacie</router-link></li>
                    <li class="nav-item mb-2"><router-link to="/en_cours" class="nav-link p-0 text-muted">Histoire</router-link></li>
                    <li class="nav-item mb-2"><router-link to="/en_cours" class="nav-link p-0 text-muted">Application</router-link></li>
                    <li class="nav-item mb-2"><router-link to="/en_cours" class="nav-link p-0 text-muted">Plan du site</router-link></li>
                    </ul>
                </div>

                <div class="col-6 col-md-2 mb-3">
                    <h5>Mentions légales</h5>
                    <ul class="nav flex-column">
                    <li class="nav-item mb-2"><router-link to="/en_cours" class="nav-link p-0 text-muted">CGV</router-link></li>
                    <li class="nav-item mb-2"><router-link to="/en_cours" class="nav-link p-0 text-muted">Transport</router-link></li>
                    <li class="nav-item mb-2"><router-link to="/en_cours" class="nav-link p-0 text-muted">Vos données personnelles</router-link></li>
                    <li class="nav-item mb-2"><router-link to="/en_cours" class="nav-link p-0 text-muted">FAQs</router-link></li>
                    <li class="nav-item mb-2"><router-link to="/en_cours" class="nav-link p-0 text-muted">Contact</router-link></li>
                    </ul>
                </div>

                <div class="col-6 col-md-2 mb-3">
                    <h5>Liens pratiques</h5>
                    <ul class="nav flex-column">
                    <li class="nav-item mb-2"><router-link to="/en_cours" class="nav-link p-0 text-muted">Gardes</router-link></li>
                    <li class="nav-item mb-2"><router-link to="/en_cours" class="nav-link p-0 text-muted">Allergies</router-link></li>
                    <li class="nav-item mb-2"><router-link to="/en_cours" class="nav-link p-0 text-muted">Cardiologie</router-link></li>
                    <li class="nav-item mb-2"><router-link to="/en_cours" class="nav-link p-0 text-muted">Ruptures médicaments</router-link></li>
                    <li class="nav-item mb-2"><router-link to="/en_cours" class="nav-link p-0 text-muted">Recyclage</router-link></li>
                    </ul>
                </div>

                <div class="col-md-5 offset-md-1 mb-3">
                    <form>
                    <h5>S'inscrire à la Newsletter</h5>
                    <p>Nous serions heureux de garder le contact.</p>
                    <div class="d-flex flex-column flex-sm-row w-100 gap-2">
                        <label for="newsletter1" class="visually-hidden">Adresse e-mail</label>
                        <input id="newsletter1" type="text" class="form-control" placeholder="Adresse e-mail">
                        <button class="btn btn-outline-success" type="button">S'inscrire</button>
                    </div>
                    </form>
                </div>
                </div>

                <div class="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                <p>&copy; {{ year }} ShopFlash, Inc. Tous droits réservés.</p>
                <ul class="list-unstyled d-flex">
                    <li class="ms-3"><a class="text-muted" href="#"><img class="bi" width="50" src="../../assets/logos/logo-ordre-national-des-pharmaciens.jpeg" /></a></li>
                            <li class="ms-3"><a class="text-muted" href="#"><img class="bi" width="60" src="../../assets/logos/logo-certification-pharmacie.png" /></a></li>
                            <li class="ms-3"><a class="text-muted" href="#"><img class="bi" width="60"  src="../../assets/logos/logo-ars-idf.png"/></a></li>
                            <li class="ms-3"><a class="text-muted" href="#"><img class="bi" width="60"  src="../../assets/logos/logo-ministere-sante.png"/></a></li>
                </ul>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
  name: 'FooterBar',
  computed: {
    year () {
      const date = new Date()
      return date.getFullYear()
    }
  }
}
</script>
<style lang="css" scoped>
    .container{
        background-color: #FFFFC7;
    }
</style>
