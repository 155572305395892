<template>
    <div>
        <h3>Votre compte utilisateur:</h3>
        <div class="btn btn-outline-success" @click="billingAdress">
            Mes coordonnées
        </div>
        <div class="btn btn-outline-success" @click="myOrders">
            Mes commandes
        </div>
        <p>
            {{ user }}
        </p>
            <div v-if="showBillingAdress">
                <p>Adresse: {{ dataBillingAdress.address }}</p>
                <p>Ville: {{ dataBillingAdress.city }}</p>
                <p>Code postal: {{ dataBillingAdress.zipcode }}</p>
                <p>Pays: {{ dataBillingAdress.country }}</p>
                <form-adress/>

            </div>
            <div v-if="showMyOrders">
                <h3>Mes commandes:</h3>
                <div
                v-for="order in orders"
                :key="order.id"
                >
                <div class="btn btn-sm-succes">
                    <a :href="loadInvoice(order.urlInvoiceFile)"> {{ order.urlInvoiceFile}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormAdress from '@/components/orders/ordersElements/FormAdress.vue'
import { mapState } from 'vuex'
// import Invoices from '@/components/orders/ordersElements/Invoices.vue'
export default {
  name: 'AccountUserView',

  components: { FormAdress },

  data () {
    return {
      userProfile: {
        name: '',
        email: ''
      },
      showBillingAdress: false,
      dataBillingAdress: {
        address: '',
        zipcode: '',
        city: '',
        country: ''
      },
      showMyOrders: false,
      orders: []
    }
  },

  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },

  async created () {
    this.userProfile = await fetch(process.env.VUE_APP_BASE_URL + '/api/users/' + sessionStorage.getItem('id'), {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('tok')
      }
    })
      .then(Response => {
        return Response.json()
      })
      .then(json => {
        console.log(json)
        console.log(json.email)
      })
  },

  methods: {

    billingAdress () {
      this.showBillingAdress = !this.showBillingAdress
      this.showMyOrders = false
      console.log(this.showBillingAdress)
      fetch(process.env.VUE_APP_BASE_URL + '/api/user_addresses', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('tok')
        }
      })
        .then(Response => {
          return Response.json()
        })
        .then(json => {
          let i
          console.log(json['hydra:totalItems'])
          const totalItems = json['hydra:totalItems']
          console.log(json['hydra:member'])
          const adresses = json['hydra:member']
          for (i = 0; i < totalItems; i++) {
            if (adresses[i].userId === '/api/users/' + sessionStorage.getItem('id')) {
              this.dataBillingAdress.address = adresses[i].address
              this.dataBillingAdress.zipcode = adresses[i].zipcode
              this.dataBillingAdress.city = adresses[i].city
              this.dataBillingAdress.country = adresses[i].country
            }
          }
        })
    },

    async myOrders () {
      this.showMyOrders = !this.showMyOrders
      this.showBillingAdress = false
      this.orders = await fetch(process.env.VUE_APP_BASE_URL + '/api/getInvoice/' + sessionStorage.getItem('id'), {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('tok')
        }
      })
        .then(response => {
          return response.json()
        })
        .then(json => {
          const data = json.invoices
          console.log(data)
          const dataJson = JSON.parse(data)
          console.log(dataJson)
          console.log(dataJson.length)
          return dataJson
        })
    },

    loadInvoice ($orderToLoad) {
      return process.env.VUE_APP_BASE_URL + '/api/invoicesDoc/' + $orderToLoad
    }
  }
}
</script>
