import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import ProductsView from '@/views/ProductsView.vue'
import OrderView from '@/views/OrderView.vue'
import ResultCB from '@/views/ResultCB.vue'
import AccountUserView from '@/views/AccountUserView.vue'
import NotFinished from '@/views/Info.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/produits',
    name: 'products',
    component: ProductsView
  },
  {
    path: '/commande',
    name: 'order',
    component: OrderView
  },
  {
    path: '/resultCB',
    name: 'resultCB',
    component: ResultCB
  },
  {
    path: '/compte_utilisateur',
    name: 'accountUser',
    component: AccountUserView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/en_cours',
    name: 'en_cours',
    component: NotFinished
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

export default router
