export default {
  namespaced: true,

  state: {
    loading: false
  },

  getters: {
    getLoading (state) {
      return state.loading
    }
  },

  mutations: {
    SET_LOADING_EFFECT (state, payload) {
      console.log('Effect: ' + payload)
      state.loading = payload
    }
  },

  actions: {
    loadingEffect (context, item) {
      context.commit('SET_LOADING_EFFECT', item)
    }
  }
}
