<template>
    <div class="products">
            <breadcrumbs />
        <div
        id= "productsList"
        v-if= "product == null"
        >
            <div
            v-for="(product, id) in products"
            :key="id"
            >
            <card-product
            :id = 'product.id'
            :id_notice = 'product.id_notice'
            :new = 'product.new'
            :name = 'product.name'
            :description = 'product.description'
            :price = 'product.price'
            :imageFile = "product.image_file"
            />

            </div>
        </div>
        <div
        id= "oneProduct"
        v-else
        >
          <div class="card text-center">
          <div class="card-header">
            <ul class="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Produit</button>
              </li>
              <div
                  v-for="(item, rubrique) in notice"
                  :key="rubrique"
                >
                <li class="nav-item" role="presentation">
                    <button class="nav-link" :id="rubrique" data-bs-toggle="tab" :data-bs-target="`#${rubrique}-pane`" type="button" role="tab" :aria-controls="`${rubrique}-pane`" aria-selected="false">{{rubrique}}</button>
                </li>
              </div>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Description</button>
              </li>
            </ul>
          </div>
          <div class="card-body">
            <div class="tab-content" id="myTabContent" style="display: flex">
              <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                <img :src= "image_base_url + product.image_file" alt="product.name" class="card-img-left" >
                <div>
                  <h5 class="card-title">{{ product.name }}</h5>
                  <h5 class="card-title">{{ product.price }}€</h5>
                  <p class="card-text">{{ product.description }}</p>
                  <div class="input-group" style="justify-content: center">
                    <input type="number" min="1" style="width:20%" v-model="product.quantity" placeholder="0" required>
                    <button class="btn btn-sm btn-outline-success" @click="fillCart(product.id, product.name, product.price, product.quantity, product.image_file)">Ajouter <i class="bi bi-cart-plus"></i></button>
                  </div>
                </div>
              </div>

              <div
              v-for="(item, rubrique) in notice"
              :key="rubrique"
              >
                <div class="tab-pane fade" :id="`${rubrique}-pane`" role="tabpanel" :aria-labelledby="rubrique" tabindex="0">
                  <notice-component :rubrique="item" />
                </div>

              </div>
              <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">...</div>
            </div>
          </div>
          </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import NoticeComponent from '../components/products/notice.vue'
import Breadcrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
import CardProduct from '@/components/products/card_product.vue'
export default {
  name: 'ProductsView',
  components: {
    CardProduct,
    NoticeComponent,
    Breadcrumbs
  },
  data () {
    return {
      image_base_url: process.env.VUE_APP_BASE_URL + '/images/products/',
      productToBuy: []
    }
  },
  computed: {
    ...mapState({
      products: state => state.products.products,
      product: state => state.products.product,
      notice: state => state.products.notice
    }),
    ...mapActions(['products/productsList'], ['products/product_show'], ['products/productNotice'])
  },
  async created () {
    this.$store.dispatch('products/productsList')
  },
  methods: {
    showProduct (id, productName, idNotice) {
      this.$store.dispatch('breadcrumbs/addInBreadCrumbs', productName)
      this.$store.dispatch('products/product_show', id)
      if (idNotice !== undefined) {
        this.$store.dispatch('products/productNotice', idNotice)
      } else {
        this.$store.dispatch('products/resetNotice')
      }
    },

    fillCart (id, name, price, quantity, imageFile) {
      const prod =
        {
          id,
          name,
          price,
          quantity,
          imageFile
        }
      console.log('panier' + prod)
      if (prod.quantity) {
        this.$store.dispatch('cart/addInCart', prod)
      }
    }
  }

}
</script>

<style lang="css" scoped>
    #productsList{
        padding: 20px;
        margin: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .image-container{
      height: 288px;
      width:  288px;
      display: flex;
      justify-content: center;
    }
    .card-img-top{
      width: auto;;
      height: auto;
      max-height: 100%;
      max-width: 100%;
      display: block;
    }
    .card-body{
      height: auto;
    }

    .card-img-left{
      max-width: 300px;
    }

    .card-title-name{
      height: 50px;
      overflow: hidden;
    }

</style>
