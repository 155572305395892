<template>

    <div class="container marketing">

    <!-- Three columns of text below the carousel -->
    <div class="row">

      <div
      v-for="(sticker, index) in dataStickers"
      :key="index"
      class="col-lg-4"
      >
          <img class="bd-placeholder-img rounded-circle" :src="sticker.url_image" :alt="sticker.title" height="140">
          <h2 class="fw-normal">{{ sticker.title}}</h2>
          <p>{{ sticker.description }}</p>
          <p><a class="btn btn-outline-secondary" :href="sticker.link_products">Voir &raquo;</a></p>
      </div><!-- /.col-lg-4 -->
    </div>
    <hr class="featurette-divider">

    <!--------New products-------->
    <h3>Nouveaux produits:</h3>
    <div class="newProducts">
      <div
            v-for="(product, id) in newProducts"
            :key="id"
            >
        <card-product
        :id = "product.id"
        :id_notice = "product.id_notice"
        :new = "product.new"
        :name = "product.name"
        :description = "product.description"
        :price = "product.price"
        :imageFile = "product.imageFile"
        />
      </div>
    </div>
    <!-- START THE FEATURETTES -->

    <hr class="featurette-divider">
    <div
      v-for="(theme, index) in dataThemes"
      :key="index"
      >
        <div class="row featurette">
          <div :class="theme.id === dataThemes[1].id ? 'col-md-7 order-md-2' : 'col-md-7'">
            <h2 class="featurette-heading fw-normal lh-1">{{ theme.title }}</h2>
            <p class="lead">{{ theme.description }}</p>
          </div>
          <div class="col-md-5">
            <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" :src="theme.url_image" :alt="theme.id" width="500" height="500">

          </div>
        </div>

        <hr class="featurette-divider">
    </div>

    <!-- /END THE FEATURETTES -->
  <h3>Les marques:</h3>
<div class="brand-images">
  <div
    v-for="(brand, item) in brandsTab"
    :key="item"
  >
   <img :src="brand.imagePath" alt="brand.name" width="150" @click="getProductsBrand(brand.id, brand.name)">
  </div>
</div>
  <hr class="featurette-divider">
  </div><!-- /.container -->

</template>

<script>
import { mapState, mapActions } from 'vuex'
import CardProduct from '@/components/products/card_product.vue'
export default {
  name: 'WelcomeHome',
  props: {
    msg: String
  },
  components: {
    CardProduct
  },
  data () {
    return {
      image_base: process.env.VUE_APP_BASE_URL,
      data_sticker_url: process.env.VUE_APP_BASE_URL + '/api/sticker',
      data_url: process.env.VUE_APP_BASE_URL + '/api',
      dataStickers: [],
      dataThemes: [],
      newProducts: [],
      brandsTab: []
    }
  },
  computed: {
    ...mapState({
      products: state => state.products.products,
      brands: state => state.brands.brands
    }),
    ...mapActions(['products/productsList'])
  },
  created () {
    this.getStickers()
    this.getTheme()
    this.getNewProducts()
    this.getBrands()
  },
  methods: {
    async getStickers () {
      const response = await fetch(this.data_url + '/sticker')
      const json = await response.json()
      console.log(json)
      this.dataStickers = json
    },

    async getTheme () {
      const response = await fetch(this.data_url + '/theme')
      const json = await response.json()
      console.log(json)
      this.dataThemes = json
    },

    async getNewProducts () {
      await this.$store.dispatch('products/productsList')
      console.log(this.products)
      for (const key in this.products) {
        console.log(this.products[key].new)
        if (this.products[key].new === true) {
          this.newProducts.push({
            id: this.products[key].id,
            idNotice: this.products[key].idNotice,
            new: this.products[key].new,
            name: this.products[key].name,
            description: this.products[key].description,
            price: this.products[key].price,
            imageFile: this.products[key].image_file
          })
        }
      }
      console.log(this.newProducts)
    },

    async getBrands () {
      await this.$store.dispatch('brands/brandsList')
      console.log(this.brands)
      for (const key in this.brands) {
        this.brandsTab.push({
          id: this.brands[key].id,
          name: this.brands[key].name,
          imagePath: this.brands[key].imagePath
        })
      }
      console.log(this.brandsTab)
    },

    async getProductsBrand (brandId, brandName) {
      this.$router.push('/produits')
      this.$store.dispatch('breadcrumbs/cleanBreadCrumbs')
      this.$store.dispatch('breadcrumbs/addInBreadCrumbs', brandName)
      this.$store.dispatch('products/resetProduct')
      this.$store.dispatch('products/productsBrand', brandId)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.newProducts{
  padding: 20px;
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.brand-images{
  padding: 20px;
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;
}
/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: .75rem;
  margin-left: .75rem;
}
/* rtl:end:ignore */

/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
/* rtl:begin:remove */
.featurette-heading {
  letter-spacing: -.05rem;
}

/* rtl:end:remove */
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
