<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-dark" style="background-color: #1EAE98">
            <div class="container-fluid">
            <router-link class="navbar-brand" to="/" @click="reset" style="color: white"><strong>Shop-Flash</strong></router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">

                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item dropdown">
                        <a class="nav-link active dropdown-toggle" href="#" id="categoriesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Catégories
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="categoriesDropdown" style="margin: 0">
                            <categories-list />
                            <li><hr class="dropdown-divider"></li>
                            <li class="dropdown-item"><a class="dropdown-item" href="#" data-bs-toggle='collapse' data-bs-target=".navbar-collapse.show" disabled>Promotions</a></li>
                            <li><hr class="dropdown-divider"></li>
                            <li class="dropdown-item"><a class="dropdown-item" href="#"  data-bs-toggle='collapse' data-bs-target=".navbar-collapse.show" disabled>Nouveautés</a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                      <a class="nav-link active dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Marques
                      </a>
                      <ul class="dropdown-menu" style="margin: 0">
                          <brand-list />
                      </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link disabled">Ordonnances</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link disabled">Locations</a>
                    </li>
                </ul>
                 <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <div style="background-color: white; padding-left: 30px;  border-radius: 20px">
                  <form class="d-flex" role="search" style="background-color: white; border-radius: 20px">
                    <div class="input-group input-group-sm">
                      <input class="form-control me-2" type="search" id="input-search-list" placeholder="Recherche par produits, catégories, marques ..." aria-label="Search" @keyup="search" v-model="searchElement" size="100" style="background-color: white; border: none">
                      <button class="btn btn-outline-secondary" @click="search()" style="background-color: white; border: none; border-radius: 20px"><i class="bi bi-search" style="background-color: white; color: black; border-radius: 20px"></i></button>
                    </div>
                  </form>
                  <ul class="list-group list-group-flush">
                    <div
                    class="popRecherche"
                    style="background-color: white"
                    v-for= "(prod, id) in filteredProds"
                    :key="id"
                    >
                        <a @click="goToProduct(prod.id, prod.id_notice)" class="list-group-item list-group-item-action" style="cursor: pointer">{{ prod.name }}</a>
                    </div>
                    <div
                    class="popRecherche"
                    style="background-color: white"
                    v-for= "(categ, id) in filteredCategs"
                    :key="id"
                    >
                        <a @click="goToCateg(categ.id, categ.name)" class="list-group-item list-group-item-action" style="cursor: pointer">{{ categ.name }}</a>
                    </div>
                    <div
                    class="popRecherche"
                    style="background-color: white"
                    v-for= "(brand, id) in filteredBrands"
                    :key="id"
                    >
                        <a @click="goToBrand(brand.id, brand.name)" class="list-group-item list-group-item-action" style="cursor: pointer">{{ brand.name }}</a>
                    </div>
                  </ul>
                </div>
                 </ul>

                  <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item dropdown" v-show="user">
                        <a class="nav-link active dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Mon compte
                        </a>
                        <ul class="dropdown-menu" style="margin: 0">
                          <li class="dropdown-item">
                            <div class="dropdown-item">Bonjour, {{ user }}!</div>
                          </li>
                          <li><hr class="dropdown-divider"></li>
                            <li class="dropdown-item">
                              <router-link class="dropdown-item" to="/compte_utilisateur" @click="collapseNavbar">Profile</router-link>
                            </li>
                            <li><hr class="dropdown-divider"></li>
                             <li class="dropdown-item">
                                <a type="button" class="dropdown-item" data-bs-toggle='collapse' data-bs-target=".navbar-collapse.show" @click="handleClick" >Déconnexion</a>
                             </li>
                        </ul>
                    </li>
                  </ul>

                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item"><login-component v-show="!user"/></li>
                  <li class="nav-item"><register-component v-show="!user"/></li>
                </ul>

                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                        <cart-component style="cursor: pointer"/>
                    </li>
                </ul>

              </div>
          </div>
        </nav>
    </div>
</template>

<script>
import RegisterComponent from '@/components/user/RegisterComponent.vue'
import LoginComponent from '@/components/user/LoginComponent.vue'
import CartComponent from '@/components/cart/CartComponent.vue'
import CategoriesList from '@/components/categories/Categories.vue'
import BrandList from '@/components/brands/Brands.vue'
import { mapState } from 'vuex'

export default {
  name: 'NavBar',
  data () {
    return {
      searchElement: '',
      filteredProds: [],
      filteredCategs: [],
      filteredBrands: []
    }
  },
  components: {
    RegisterComponent,
    LoginComponent,
    CartComponent,
    CategoriesList,
    BrandList
  },
  computed: {
    ...mapState({
      products: state => state.products.products,
      categories: state => state.categories.categories,
      brand: state => state.brands.brands,
      user: state => state.user.user
    })
  },
  methods: {
    // Fermeture de la navbar sur ecran de petite taille
    collapseNavbar () {
      const navbar = document.getElementById('navbarSupportedContent')
      navbar.classList.remove('show')
    },

    // Suppression du sessionstorage à la deconnexion
    handleClick () {
      sessionStorage.removeItem('tok')
      sessionStorage.removeItem('name')
      sessionStorage.removeItem('email')
      sessionStorage.removeItem('id')
      sessionStorage.removeItem('cart')
      sessionStorage.removeItem('cart/amountCart')
      this.$store.dispatch('cart/cleanCart')
      this.$store.dispatch('user/newUser', null)
      this.$router.push('/')
    },

    accountUser () {
      this.collapseNavbar()
      this.$router.push('/accountUser')
    },

    // permet de remettre product = null dans le state
    reset () {
      this.filteredProds = [] // vidage du tableau
      this.filteredCategs = [] // vidage du tableau
      this.filteredBrands = [] // vidage du tableau
      this.$store.dispatch('breadcrumbs/cleanBreadCrumbs') // remet le breadcrumbs à vide
      this.$store.dispatch('products/resetProduct') // remet product = null dans le state
      this.collapseNavbar()
    },

    search () {
      if (this.searchElement !== '') {
        // contenu du champ de recherche
        const value = (this.searchElement).toUpperCase()

        // recherche par nom de medicament
        this.$store.dispatch('products/productsList')
        const prods = this.$store.state.products.products
        this.filteredProds = prods.filter(prod => ((prod.name).toUpperCase()).includes(value) === true)
        console.log(this.filteredProds)

        // recherche par categorie
        this.$store.dispatch('categories/categoriesList')
        const categs = this.$store.state.categories.categories
        this.filteredCategs = categs.filter(categ => ((categ.name).toUpperCase()).includes(value) === true)
        console.log(this.filteredCategs)

        // recherche par marque
        this.$store.dispatch('brands/brandsList')
        const brandsTab = this.$store.state.brands.brands
        this.filteredBrands = brandsTab.filter(brand => ((brand.name).toUpperCase()).includes(value) === true)
        console.log(this.filteredBrands)
      } else {
        this.filteredProds = []
        this.filteredCategs = []
        this.filteredBrands = []
      }
    },

    goToProduct (id, idNotice) {
      this.reset()
      this.$store.dispatch('products/product_show', id)
      if (idNotice !== null) {
        this.$store.dispatch('products/productNotice', idNotice)
      }
      this.$router.push('/produits')
    },

    goToCateg (id, categName) {
      this.reset()
      this.$store.dispatch('breadcrumbs/addInBreadCrumbs', categName)
      this.$store.dispatch('products/productsCategory', id)
      this.$router.push('/produits')
    },

    goToBrand (id, brandName) {
      this.reset()
      this.$store.dispatch('breadcrumbs/addInBreadCrumbs', brandName)
      this.$store.dispatch('products/productsBrand', id)
      this.$router.push('/produits')
    }
  }
}
</script>
<style lang="css" scoped>

</style>
