<template>
    <div
    class="card-rubrique"
    v-for="(item, index) in rubrique"
    :key="index"
    >
       <p>{{ item }}</p>
    </div>
</template>
<script>
export default {
  name: 'NoticeComponent',
  props: ['rubrique']
}
</script>
<style lang="css">
    .card-rubrique{
        display: flex;
    }
</style>
